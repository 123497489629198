import { Component, Prop } from 'vue-property-decorator';

import { Headline } from '~/components/atoms';
import { Align } from '~/components/atoms/headline/Headline';
import { MediaBackground } from '~/components/molecules';
import { Sizes } from '~/components/molecules/imageWrapper/ImageWrapper';
import { CztImage } from '~/utils/atoms/image';
import { ThemeColors } from '~/utils/theme';
import { CztWidgets } from '~/utils/views/widgets';
import { VueComponent } from '~/utils/vue-component';
import { LocaleMessage } from 'vue-i18n';

import style from './Faq.scss';
import { FaqInterface, FaqItemInterface } from './types';
import HtmlRenderer from '~/components/templates/base/HtmlRenderer';

const sizes: Sizes[] = [
  {
    size: 100,
    unit: 'vw',
  },
];

const rootClass = 'czt-faq';

@Component({
  style,
})
export default class Faq extends VueComponent<FaqInterface>
  implements FaqInterface {
  @Prop()
  public anchorId?: string;

  @Prop({ required: true, type: Array })
  public items!: FaqItemInterface[];

  @Prop({ required: true })
  public title!: string | LocaleMessage;

  @Prop()
  public content?: string;

  @Prop({ default: false, type: Boolean })
  public side!: boolean;

  @Prop()
  public image?: CztImage;

  @Prop({ default: false })
  public isBottomSpacingCollapsed!: boolean;

  @Prop({ default: false })
  public isTopSpacingCollapsed!: boolean;

  @Prop({ default: 0, type: Number })
  public maxItems!: number;

  public className = CztWidgets.FAQ;

  public render() {
    if (this.image) {
      return (
        <MediaBackground
          id={this.anchorId}
          class={rootClass}
          image={this.image}
          sizes={sizes}
          overlayColor={
            this.image && this.image.src ? ThemeColors.PRIMARY : undefined
          }
        >
          {this.renderContent()}
        </MediaBackground>
      );
    } else {
      return (
        <v-sheet id={this.anchorId} class={rootClass}>
          {this.renderContent()}
        </v-sheet>
      );
    }
  }

  protected renderContent() {
    const containerClasses = ['czt-spacer'];

    if (!this.image && this.side) {
      containerClasses.push('px-0');
    }

    if (this.isTopSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-top');
    }
    if (this.isBottomSpacingCollapsed) {
      containerClasses.push('czt-spacer--collapse-bottom');
    }

    return (
      <v-container class={containerClasses.join(' ')}>
        <div class='py-3'>
          {(!!this.title || !!this.content) && (
            <v-row>
              {this.title && this.getHeadline()}
              {this.content && (
                <v-col class='pt-md-0' cols={12}>
                  <div class='czt-richtext'>
                    <HtmlRenderer
                      content={this.content}
                      light={!!this.image?.src}
                      side={this.side}
                    />
                  </div>
                </v-col>
              )}
            </v-row>
          )}
          <v-row class='ma-0 pa-0'>
            <v-col class='py-0'>
              <v-row class={`${rootClass}__container`}>
                <v-expansion-panels
                  class={`${rootClass}__accordion`}
                  focusable
                  itemscope
                  itemtype='https://schema.org/FAQPage'
                >
                  {this.getItems()}
                </v-expansion-panels>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    );
  }

  protected getHeadline() {
    return (
      <v-col class='py-0'>
        <Headline
          underscore
          align={Align.LEFT}
          level={this.side ? 4 : 2}
          light={!!this.image?.src}
        >
          {this.title}
        </Headline>
      </v-col>
    );
  }

  public scrollToPanel(e: MouseEvent) {
    const target = e.currentTarget;
    if (target instanceof HTMLElement) {
      const isPanelActive = target.classList.contains(
        'v-expansion-panel-header--active'
      );

      if (!isPanelActive) {
        setTimeout(() => {
          const scrollTop =
            window.scrollY || document.documentElement.scrollTop;
          const scrollTo = target.getBoundingClientRect().top + scrollTop;
          const offset =
            window.innerHeight / 2 - target.getBoundingClientRect().height / 2;

          window.scrollTo({
            top: scrollTo - offset,
            behavior: 'smooth',
          });
        }, 500);
      }
    }
  }

  // TODO: In case CZT wants the panel click to center the viewport on the faq item, add onClick={this.scrollToPanel} to v-expansion-panel-header
  protected getItems() {
    return this.items.map((item, index) => (
      <v-expansion-panel
        key={`${item.guid}-${index}`}
        class={{ 'd-none': !!this.maxItems && this.maxItems <= index }}
        itemscope
        itemprop='mainEntity'
        itemtype='https://schema.org/Question'
      >
        <v-expansion-panel-header>
          <Headline
            level={this.side ? 5 : 3}
            align={Align.LEFT}
            itemprop='name'
          >
            {item.question}
          </Headline>
        </v-expansion-panel-header>
        <v-expansion-panel-content eager>
          <div
            class='pt-3'
            itemscope
            itemprop='acceptedAnswer'
            itemtype='https://schema.org/Answer'
          >
            <HtmlRenderer content={item.answer} itemprop='text' />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    ));
  }
}
